body {
  font-family: 'Exo', sans-serif;
}
.somi{
  padding: 0;
}
.positionRelative {
  position: relative;
}
.imgRes {
  width: 100%;
}

.navFixed {
  position: fixed !important;
  width: 100%;
  z-index: 99999;
}
.navbar-light .navbar-toggler {
  color: #ffffff;
    border-color: #d31d1d;
    background: #fc3a45;
}
.sliderText {
  font-family: 'Antonio', sans-serif;
  font-size: 4.5rem !important;
  line-height: 1.033;
  font-weight: 700;
  color: #fff;
}

.sliderText span {
  color: #fc3a45;
}

.carousel-caption {
  position: absolute;
  right: inherit !important;
  bottom: inherit !important;
  left: 6% !important;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: inherit;
  text-align: left !important;
  top: 30%;
}

.bg-body-tertiary {
  background-color: transparent;
  transition: all .5s ease;
}

.navFixedWhite {
  position: fixed !important;
  width: 100%;
  z-index: 99999;
  background-color: #ffffff;
  transition: all .5s ease;
  box-shadow: 0 0 10px #c0c0c0;
  /* padding: 0; */
}

.shadow {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 30%;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 16%, rgba(255, 255, 255, 0) 100%);
}

.logoWhite {
  width: 100px;
}
.svgLogo{
  width: 50px;
    height: 50px;
    fill: #fefeff;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(255 255 255);
}

.socialLinks i {
  color: #fff;
  cursor: pointer;
  font-size: 18px;
}

.nav-link {
  font-family: 'Exo', sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}

.socialLinks .nav-link {
  padding: 0.5rem 5px;
  color: #ffffff;
  font-family: 'Exo', sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}
.navFixedWhite .nav-link {
  color: #000!important;
}

.navFixedWhite .svgLogo{
  fill: #000000;
}

.navFixedWhite .socialLinks i {
  color: #000000;
}
.sliderBox {
  margin-top: -8em;
  position: relative;
  z-index: 9999;
}

.boxStyle {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(219, 219, 219);
  min-height: 200px;
  border-radius: 16px;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.0) 0%, white 100%, white 100%);
  backdrop-filter: blur(8px);
  overflow: hidden;
}

.innerImgBox {
  background-color: #fefeff;
  height: 200px !important;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
}

.boxContent {
  padding: 20px;
}

.boxContent h2 {
  font-size: 21px !important;
  font-weight: 700;
  font-family: 'Antonio', sans-serif;
  color: #000000;
  line-height: 32px;

}

.boxContent h2 span {
  font-family: 'Antonio', sans-serif;
    color: #fc3a45;
    margin-top: 1em;
    font-size: 15px;
}

.buttonCustomRegular {
  background-color: #fc3a45;
    color: #fff!important;
    padding: 10px 35px;
    border-radius: 30px;
    border: none;
    width: 175px;
    text-decoration: none;
    display: block;
}
.linkClass{
  text-decoration: none;
  color: #fff;
}
.linkClass:hover{
  text-decoration: none;
  color: #fc3a45;
}
.footer {
  background: #000000;
}

.footerTitle {
  color: #fff;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.footerTop {
  padding-top: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #383636;
}

.footerBottom {
  padding-top: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #383636;
}

.footerTitleSmall {
  color: #fff;
}

.footerLinks {
  color: #ffffff;
  padding: 0;
  list-style: none;
}

.footerLinks li {
  padding-bottom: 10px;
}

.copyright {
  color: #ffffff;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 5px;
}

.HomePatchTwo {
  padding-top: 7em;
  padding-bottom: 1em;
}


.pageIco {
  font-size: 47px;
  color: #fc3a45;
}
/* .video-container{
  width: 100vw;
  height: 100vh;
}

 iframe {
  position: relative;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

#text{
  position: absolute;
  color: #FFFFFF;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (min-aspect-ratio: 16/9) {
  .video-container iframe {
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-container iframe {
    width: 177.78vh;
  }
} */
.videoContainer{
  width: 100%;
  margin-top: 3em;
  /* background-image: url(../src/assets/images/video-background.jpg);
  background-size: cover;*/
  position: relative; 
  height: 85vh;
  overflow: hidden;
}
.videoContainer video{
  width: 100%;
}
.videoTitle{
  position: absolute;
  bottom:20%;
width: 100%;
text-align: center;
}
.videoTitle h2{
  color: #ffffff;
  font-family: 'Antonio', sans-serif;
    font-size: 6.5rem !important;
    line-height: 1.033;
    font-weight: 700;
}
.videoTitle h2 span{
  color: #fc3a45;
}
.pngTitle h2{
  color: #000000;
  font-family: 'Antonio', sans-serif;
    font-size: 6.5rem !important;
    line-height: 1.033;
    font-weight: 700;
    margin-bottom: 1em;
}
.pngTitle h2 span{
  color: #fc3a45;
}
.videoContentContainer{
  margin-top: -4em;
  position: relative;
}
.contentBody{
padding-left: 15px;
padding-right: 15px;
padding-bottom: 20px;
}
.contentBody h4{
font-family: 'Antonio', sans-serif;
color: #fc3a45;
margin-top: 1em;
    font-size: 15px;
}
.contentBody h2{
font-family: 'Antonio', sans-serif;
color: #000;
font-size: 2rem;
}
.contentBodyAbout h4{
font-family: 'Antonio', sans-serif;
color: #fc3a45;
margin-top: 1em;
    font-size: 15px;
}
.contentBodyAbout h2{
font-family: 'Antonio', sans-serif;
color: #000;
font-size: 2rem;
}
.boxPlay{
  border: 1px solid #dcdcdc;
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
}
.AboutContainerPatch{
  margin-top: 6em;
  margin-bottom: 4em;
}
.skills i{
  color:#fc3a45;
  font-size: 50px;
  margin-bottom: 20px;
}
.skills h2{
  font-family: 'Antonio', sans-serif;
color: #000;
font-size: 2rem;
}
.homePatchBottom{
  background:url(../src/assets/images/featured_image_blog.jpg);
  background-size: cover;
  color:#ffffff;
  min-height: 100vh;
}
.shadowLayer{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(252, 58, 68, 0.539);
}
.homePatchBottom h4{
  font-family: 'Antonio', sans-serif;
}

.homePatchBottom h2{
  font-family: 'Antonio', sans-serif;
font-size: 2rem;
margin-bottom: 1em;
}
.mgTop3{
  padding-top: 3em;
  position: relative;
}
.floatingDiv{
  position: absolute;
  bottom:5%;
  left: 0;
}
.aboutCarousel .carousel-control-prev, .aboutCarousel .carousel-control-next {
  display: none;
}
.aboutCarCaption{
  border-left: 1rem solid #fc3a45;
    padding: 0;
    padding-left: 1rem;
}
.sliderSmTitle {
  font-family: 'Antonio', sans-serif;
  color: #fc3a45;
  font-size: 24px;
}
.formContainer{
  padding-top: 5em;
  padding-bottom: 5em;
}
.formContainer h4{
  font-family: 'Antonio', sans-serif;
font-size: 2rem;
margin-bottom: 1em;
}
.inputType{
  border: none;
  border-bottom: 1px solid #c0c0c0;
  padding: 10px 5px;
  width: 100%;
  margin-bottom: 15px;
}

.fontColorBlack {
  color: #000000;
}
.marginBtm4{
  margin-bottom: 4em;
}
.marginTop2{
  margin-top: 2em;
}

.ImgBgContact{
background-image: url(./assets/images/gym.jpg);
background-size: cover;
background-position: center;
background-attachment: fixed;
}
.google-maps {
  position: relative;
  padding-bottom: 35%; 
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.bgPinkShade{
  background-color: rgba(10, 10, 10, 0.6);
}
.borderRight{
  text-align: right;
  border-right: 1em solid #ffffff;
  padding-right: 20px;
  margin-top: 2em;
}
.addressClass {
  color: #fff;
  text-align: right;
  padding-left: 50%;
  padding-top: 2em;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #fc3a45;
}
.contentBodyTitle {
  font-family: 'Antonio', sans-serif;
  font-size: 2rem;
}
.desktopHide{
  display: none;
}


@media only screen and (max-width: 670px) {
  .sliderText {
    font-size: 2.5rem !important;
}
.formContainer h4 {
  font-size: 1.5rem;
}
.mobHide{
  display: none;
}
.desktopHide{
  display: block;
}
.formContainer {
  padding-top: 2em;
  padding-bottom: 2em;
}

.addressClass {
  padding-left: 0%;
  padding-top: 1em;
}
.google-maps {
  padding-bottom: 70%; 
}
.sliderBox {
  margin-top: 2em;
}
.skills h2 {
  font-size: 1.4rem;
}
.videoTitle h2 {
  font-size: 2.5rem !important;
}
.videoTitle {
  bottom: 40%;
}
.AboutContainerPatch {
  margin-top: 2em;
  margin-bottom: 2em;
}
.pngTitle h2 {
  font-size: 2.5rem !important;
}
.contentBody h2 {
  font-size: 1.6rem;
}

.contentBodyTitle {
  font-size: 1.6rem;
}
.contentBodyAbout h2 {
  font-size: 1.6rem;
}
.HomePatchTwo {
  padding-top: 3em;
  padding-bottom: 0em;
}
.videoContainer {
  height: auto;
}
p {
  font-size: 14px;
}
.buttonCustomRegular {
  padding: 6px 15px;
  min-width: 128px;
}
.boxPlay {
  margin-bottom: 1em;
}
.mobMarginBtm {
  margin-bottom: 2em;
}
.marginBtm4 {
  margin-bottom: 0;
}
.sliderSmTitle {
  font-size: 16px;
}
.carousel-caption {
  top: 38%;
}
.carousel-indicators{
  display: none;
}
.skills i {
  font-size: 33px;
}
.carousel-control-prev, .carousel-control-next{
  display: none;
}
.aboutCarCaptionMob {
  top: auto;
  bottom: 10%!important;
}
.bg-body-tertiary {
  background: rgb(94 94 94 / 67%);
}
.navFixedWhite {
  background-color: #ffffff;
}
.navbar-toggler:focus {
  box-shadow: none;
}
}